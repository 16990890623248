import { computed, effect, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IAuth, ILoginBody } from '../models/auth.model';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  //* This service's purpose is to handle users' authentication on login and logout

  private currentUser = signal<IAuth>(undefined);
  getCurrentUser = computed(() => this.currentUser())

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.currentUser.set(JSON.parse(localStorage.getItem('userData')));
    effect(() => {
      if (!this.getCurrentUser())
        this.router.navigate(['welcome/login'])
    })
  }

  /**
  * reset method: removing all auth data and going back to login page
  * @returns void
  */
  resetAuthData() {
    this.currentUser.set(null);
    this.router.navigate(['welcome/login'])
    localStorage.removeItem('userData');
  }

  /**
  * login method: set an access token to the localStorage and save it
  * @returns Observable<void>
  */
  login(loginData: ILoginBody) {
    return this.httpClient.post<IAuth>(environment.apiServer.baseAdminUrl + '/login', loginData).pipe(
      map(
        (resData: IAuth) => {
          //if there is a token, login will be successful
          if (resData && resData.token) {
            // store user details and token in local storage to keep user logged in between page refreshes
            localStorage.setItem('userData', JSON.stringify(resData));
            this.currentUser.set(resData);
          } else {
            this.currentUser.set(null);
            localStorage.removeItem('userData');
          }
        }
      )
    );
  }

  /**
  * logout method: remove the access token to allow users to logout
  * @returns void
  */
  logout() {
    this.httpClient.get<void>(environment.apiServer.baseAdminUrl + '/logout').subscribe(
      () => {
        this.resetAuthData();
      },
      () => {
        this.resetAuthData();
      }
    )
  }


}
