import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable } from 'rxjs';

//This interceptor allows to intercept requests (login) in order to set headers (required from server) for user token
@Injectable()

export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //adding authorization header with token if available setting headers are satisfied
    let currentUser = this.authService.getCurrentUser();
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          'X-Auth-Token': currentUser.token,
          'X-Content-Type-Options': 'nosniff',
          'X-XSS-Protection': '1; mode=block',
        }
      })
    }
    return next.handle(request);
  }

}
