import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LoaderService } from './shared/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'hugoofun-fe';

  constructor(
    private router: Router,
    private loaderService: LoaderService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.loaderService.setValue(true)
      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        this.loaderService.setValue(false)
      }
    })
  }
}
