import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SnackbarService } from '../services/snackbar.service';

//This interceptor allows you to deal with errors
@Injectable()

export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private snackbarService: SnackbarService,
  ) { }

  //understand if http response error have some custom error properties set by the back-end and return them
  getErrors(err, generic?) {
    if (err.error) {
      if (err.error instanceof ProgressEvent) {
        let message = "Internet connection unavailable!"
        return message;
      } else if (err.error.error) {
        return err.statusText + ": " + err.error.error
      } else if (err.error.errors) {
        let message: string = "";
        if (typeof err.error.errors === 'object') {
          Object.keys(err.error.errors).forEach(key => {
            message = err.statusText + ": " + err.error.errors[key]
          })
        } else {
          let singleError = ""
          err.error.errors.map(element => {
            Object.keys(element).forEach(key => {
              singleError = "Question " + (err.error.errors.indexOf(element) + 1) + ": "
                + element[key]
              message += singleError + '\n\r'
            })
          })
        }
        return message
      } else if (err.error.remainingAttempts) {
        return err.statusText + ': login failed. Remaining attempts: ' + err.error.remainingAttempts
      } else if (err.error.remainingAttempts === 0) {
        return err.statusText + ': remaining attempts finished.'
      } else {
        let message: string = "";
        if (typeof err.error === 'object') {
          Object.keys(err.error).forEach(key => {
            let singleError = err.statusText + ": " + err.error[key]
            message += singleError + '\n\r'
          })
        }
        return message
      }
    } else {
      return err.statusText + ": " + generic
    }
  }

  //intercepting requests and handling errors
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        let message: string = "";
        let generic: string = "";

        if (err.status === 400) {
          generic = 'sending data uncorrected. Please retry.'
          message = this.getErrors(err, generic)
        } else if (err.status === 401) {
          if (this.authService.getCurrentUser() !== null) {
            this.authService.logout();
          }
          generic = 'session expired. Please login again.'
          message = this.getErrors(err, generic)
        } else if (err.status === 403) {
          generic = 'operation failed, please try again.'
          if (this.authService.getCurrentUser() !== null) {
            this.authService.logout();
          }
          message = this.getErrors(err, generic)
        } else if (err.status === 404) {
          generic = 'not found, please try again.'
          message = this.getErrors(err, generic)
        } else if (err.status === 405) {
          generic = 'operation not allowed. Action refused.'
          message = this.getErrors(err, generic)
        } else if (err.status === 410) {
          generic = 'operation failed, please try again.'
          message = this.getErrors(err, generic)
        } else if (err.status === 500 || err.status === 503) {
          generic = 'internal server error'
          message = this.getErrors(err, generic)
        } else if (err.status === 0) {
          // A client-side or network error occurred. Handle it accordingly.
          generic = 'operation failed, please try again.'
          message = this.getErrors(err, generic)
        } else {
          generic = 'operation failed, please try again.'
          message = this.getErrors(err, generic)
        }

        if (message.length !== 0) {
          this.snackbarService.error(message, { duration: 5000 });
        }

        //sending it out
        return throwError(err);
      }))
  }
}
