import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private isLoading = new BehaviorSubject<boolean>(false);

  /**
  * get "isLoading" observable's value
  * @returns Observable<boolean>
  */
  getValue$() {
    return this.isLoading.asObservable()
  }

  /**
  * set "isLoading" behavior subject's value
  * @param value - boolean
  * @returns Observable<boolean>
  */
  setValue(value: boolean) {
    return this.isLoading.next(value)
  }

}
