import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  error(message: string = 'An error occured. Please try again later.', options?: MatSnackBarConfig<any>) {
    return this._snackBar.open(message, undefined, { ...options, panelClass: ['snackbar-error'] });
  }

  success(message: string = 'Operation completed successfully!', options?: MatSnackBarConfig<any>) {
    return this._snackBar.open(message, undefined, { ...options, panelClass: ['snackbar-success'] });
  }
}
