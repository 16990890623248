//import modules
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/modules/material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

//import components
import { AppComponent } from './app.component';
import { EditPasswordDialogComponent } from './shared/components/edit-password-dialog/edit-password-dialog.component';

//import interceptors
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';

//import services
import { LoaderService } from './shared/services/loader.service';
import { ConfirmComponent } from './shared/components/confirm/confirm.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { it } from 'date-fns/locale';

@NgModule({
  declarations: [
    AppComponent,
    EditPasswordDialogComponent,
    ConfirmComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: it },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
